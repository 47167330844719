import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';

import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import Contact from "./Contact";
import GraphingCalculator from "./GraphingCalculator";
import TextGenerator from "./TextGenerator";
import ShortDrive from "./ShortDrive";

function App() {
   return (
     <main className="App">
	   <header>
		   <h1 className="headline">Welcome to my Website!</h1>
	   </header>
	   <Routes>
	   	<Route path="/" element=<Home />/>
	   	<Route path="/contact" element=<Contact />/>
	   	<Route path="/graphingcalculator" element=<GraphingCalculator />/>
	   	<Route path="/textgenerator" element=<TextGenerator />/>
	   	<Route path="/shortdrive" element=<ShortDrive />/>
	   </Routes>
	</main>
	);
}


export default App;
