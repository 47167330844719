import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './NavigationBar.css';

export default function NavigationBar() {

	const homeButtonColor = 'rgba(0, 255, 0, 0.75)';
	const otherButtonColor = 'rgba(0, 200, 0, 0.55)';
	const dropdownButtonColor = 'rgba(0, 176, 0, 0.5)';

	return (
		<Navbar bg="dark" expand="lg" height="170px">
			<Container>
				<Navbar.Brand style={{color: homeButtonColor}} href="/">jfrankcode.dev</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav">
					<Nav className="me-auto">
						<Nav.Link style={{color: "green"}} href="/contact">Contact</Nav.Link>
							<NavDropdown style={{color: otherButtonColor}} title="Applications" id="basic-nav-dropdown">
								<NavDropdown.Item style={{color: dropdownButtonColor}} href="/textgenerator">Text Generator</NavDropdown.Item>
								<NavDropdown.Item style={{color: dropdownButtonColor}}  href="/graphingcalculator">Graphing Calculator</NavDropdown.Item>
								<NavDropdown.Item style={{color: dropdownButtonColor}} href="/shortdrive">Short Drive</NavDropdown.Item>
							</NavDropdown>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

