import React from 'react';
import './NavigationBar.css';
import NavigationBar from './NavigationBar';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

function GraphingCalculator() {
	return (
		<main className='App'>
			<header>
				<h1 className="headlines">Graphing Calculator</h1>
			</header>
			<NavigationBar className="Navibar"/>
			<body className="bodies">
				<p className="m-5">For now the proper implementation doesn't work correctly for my applications so let me redirect you to my <a href="https://github.com/JFraykay/GraphingCalculator">github</a> where the project is hosted and source code listed. In the future this will be able to have user input sent to back end to communicate with the source code and then the output will be listed back here.</p>
				<p className="m-5">This is a graphing calculator, it was made in Python using matplotlib, numpy, and pandas for the graphing and calculation capabilities. It doesn't allow for multiple calculations with the primary focus being on the graphing aspect. I made this to potentially assist those who are more visual ortiented learners, so not only would it give the answer to y for slope intercept form with the proper user inputs, but it will graph the line as well. For multiple calculations in quick successions you're better off using a real calculator.</p>
			</body>
		</main>
		);
}

export default GraphingCalculator;
