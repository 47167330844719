import React from 'react';
import './App.css';
import NavigationBar from './NavigationBar';
import 'bootstrap/dist/css/bootstrap.css';

function Home() {
   return (
     <main className="App">
	   <NavigationBar className="Navibar"/>
	   <body className="bodies">
	    <p className="m-5">Hello, and welcome to jfrankcode.dev. This website is intended to serve as my portfolio for all things programming related. It is not the most optimized or interactive website, it is for educational purposes for myself as well as an outlet to program and host the apps I create along my journey as a developer.</p>
	    <p className="m-5">This website was made with React and Node.js. I built both front and back end from scratch. I am not using a SaaS like Wix; I purchased a webserver and bought the domain. I used Apache in conjunction with React.</p>
      	    <p className="m-5">There aren't many components, the site is comprised of a main "page" which is really the parent component while the rest are children, although there are not many components to link in the first place. The children are really just a navbar which reside "adjacent" to the other children in regards to a tree format, and the other child components being "pages". So a React app is ideal in making a responsive website rather than the lingua franca of HTML, CSS, and Javascript; everything just feels fluid during development, even when making a static website such as this.</p>
	    <p className="m-5">React is highly scalable and easy to develop, and after using it for this application , I'm not sure if I would go back to the old standard unless I needed to.</p>
	    <p className="m-5" >Disclaimer: The website is under construction. For now the applications (aside from the game(s)) will link you to source code which you can place in the applicable development environment.</p>
	    </body>
	</main>
	);
}


export default Home;
