import React from 'react';
import './NavigationBar.css';
import MiniNavbar from './MiniNavbar';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

function Contact() {
	return (
		<main className='App'>
			<header>
				<h1 className="headlines">Contact</h1>
			</header>
			<MiniNavbar className="Navibar"/>
			<body className="bodies">
				<p className="m-5">You can contact me through my email, jfrankcode@outlook.com

		</p>
			<p className="m-5">You can find my github <a href="https://github.com/JFraykay">here</a>.</p>
			</body>
		</main>
		);
}

export default Contact;
