import React from 'react';
import './NavigationBar.css';
import NavigationBar from './NavigationBar';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

function ShortDrive() {
	return (
		<main className='App'>
			<header>
				<h1 className="headlines">Short Drive</h1>
			</header>
			<NavigationBar className="Navibar"/>
			<body className="bodies">
				<p className="m-5">Short Drive is a small video game I created using the Unity engine. The driving script is a bit clunky, it's more of a drifting game than anything. There are a few easter eggs for those with a keen eye.</p>
				<p className="m-5">I uploaded Short Drive to itch.io which you can find <a href="https://jfraykay.itch.io/short-drive">here</a>.</p>
			</body>
		</main>
		);
}

export default ShortDrive;
