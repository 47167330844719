import React from 'react';
import './NavigationBar.css';
import NavigationBar from './NavigationBar';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

function TextGenerator() {
	return (
		<main className='App'>
			<header>
				<h1 className="headlines">Text Generator</h1>
			</header>
			<NavigationBar className="Navibar"/>
			<body className="bodies">
				<p className="m-5">For now the proper implementation doesn't work correctly for my applications so let me redirect you to my <a href="https://github.com/JFraykay/TextGenerator">github</a> where the project is hosted and source code listed. In the future this will be able to have user input sent to back end to communicate with the source code and then the output will be listed back here.</p>
				<p className="m-5">This is a text generator. It is a simple recursive nerual network made with TensorFlow. The user types in input and out comes a sample of text which I styled from James Joyce's book Ulysses.</p>
			</body>
		</main>
		);
}

export default TextGenerator;
